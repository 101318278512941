(function () {
    window.__env = window.__env || {};

    window.__env.apiUrl = "https://api.oitcontrol.com/api";
    window.__env.csvServiceUrl = "https://api.oitcontrol.com/exporter";

    window.__env.baseUrl = "/";
    window.__env.enableDebug = true;
    window.__env.html5Mode = false;

    window.__env.ensureNavigation = true;

    window.__env.tokenLocalStorage = "token";
    window.__env.tokenHeader = "token";

    window.__env.minuteFreqRefresh = 5; // Alerts refresh rate

    window.__env.avaibleStatesPerRole = {
        GlobalAdmin: [
            "app.admin-app.center-list", // Main state
            "app.admin-app.center-detail",
        ],
        CenterAdmin: [
            "app.admin-center.personal", // Main state
        ],
        Doctor: [
            "app.admin-medical.calendar", // Main state
            "app.admin-medical.control-tomas",
            "app.admin-medical.control-tomas-detail",
            "app.admin-medical.patient-create",
            "app.admin-medical.patient-detail",
            "app.admin-medical.protocols",
            "app.admin-medical.informs",
        ],
        Nurse: [
            "app.admin-medical.calendar", // Main state
            "app.admin-medical.control-tomas",
            "app.admin-medical.control-tomas-detail",
            "app.admin-medical.patient-create",
            "app.admin-medical.patient-detail",
            "app.admin-medical.protocols",
        ]
    };
}());
